import React, { FC } from 'react';
import styles from './PageWrapper.module.scss';
import { Helmet } from 'react-helmet';
import Favicon from '../../images/favicon.png';

interface Props {
  title?: string;
}

const PageWrapper: FC<Props> = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>헤이딜러 for Kcar</title>
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
      </Helmet>
      {children}
    </React.Fragment>
  );
};

export default PageWrapper;
