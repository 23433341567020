import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ToastMessage from './components/ToastMessage/ToastMessage';
import { useRouter } from './cores/useRouter';
import { useTypedSelector } from './cores/useTypedSelector';
import base64url from 'base64-url';
import LoginPage from './pages/LoginPage/LoginPage';
import DealersPage from './pages/DealersPage/DealersPage';
import KcarAnalyticsPage from './pages/KcarAnalysisPage/KcarAnalyticsPage';
import DealerAnalyticsPage from './pages/DealerAnalyticsPage/DealerAnalyticsPage';
import BidDetailData from './pages/BidDetailData/BidDetailData';

function App() {
  const { location, history } = useRouter();
  const { token } = useTypedSelector(({ authentication }) => authentication);
  const { pathname } = location;

  useEffect(() => {
    if (!token && pathname.indexOf(`/login`) === -1) {
      const previousPath = location.pathname + location.search;
      if (previousPath !== '/') {
        const previous = base64url.encode(previousPath);
        history.replace({ pathname: '/login', search: `?previous=${previous}` });
      } else {
        history.replace({ pathname: '/login' });
      }
    }
  });

  return (
    <>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route exact path="/" component={DealersPage} />
        <Route path="/analytics/users" component={DealerAnalyticsPage} />
        <Route path="/group_activity" component={KcarAnalyticsPage} />
        <Route path="/bid_data" component={BidDetailData} />
      </Switch>
      <ToastMessage />
    </>
  );
}

export default App;
