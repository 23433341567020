import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './store';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './sagas';
import { getAuthorizationHeader } from './cores/getAuthorizationHeader';
import { QueryClient, QueryClientProvider } from 'react-query';

enableES5();
axios.defaults.baseURL = `${process.env.REACT_APP_HOST}/kcar`;

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

const queryClient = new QueryClient();
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
