import logo from './logo.png';
import calendar from './calendar.png';
import gnbChart from './gnb-chart.png';
import gnbDealer from './gnb-dealer.png';
import arrowDown from './arrow-down.svg';
import noImage from './noimage.png';

export interface Images {
  logo: any;
  calendar: any;
  gnbChart: any;
  gnbDealer: any;
  arrowDown: any;
  noImage: any;
}

const images: Images = {
  logo,
  calendar,
  gnbChart,
  gnbDealer,
  arrowDown,
  noImage,
};

export default images;