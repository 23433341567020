import React, { FC, memo, useEffect, useState } from 'react';
import styles from './DealersList.module.scss';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { DealerActions, DealerTypes } from '../../../store/dealer';
import { usePagination } from '../../../cores/usePagination';
import { useTypedSelector } from '../../../cores/useTypedSelector';
import { get, map, size } from 'lodash';
import { usePrevious } from '../../../cores/usePrevious';
import DealersListItem from './DealersListItem/DealersListItem';
import axios, { CancelTokenSource } from 'axios';
import { toLocaleString } from '../../../cores/toLocaleString';
import Loading from '../../Loading/Loading';
import DropdownField from '../../../component-system/DropdownField/DropdownField';
import { useRouter } from '../../../cores/useRouter';

interface Props {}

let cancelToken: CancelTokenSource | null = null;

const orders = [
  { name: '최근 가입순', value: 'recent' },
  { name: '성사율 순', value: 'success_rate' },
  { name: '최근 거래순', value: 'recent_traded_cars_count' },
  { name: '누적 거래순', value: 'traded_cars_count' },
  { name: '평점 순', value: 'ratings' },
  { name: '소속 지점 이름순', value: 'office' }
];

const DealersList: FC<Props> = memo(() => {
  const { search, key } = useLocation();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { history } = useRouter();

  const { isLoading, dealers, dealersCount, isStopPagination } = useTypedSelector(
    ({ dealer: { dealers, dealersCount, isStopPagination }, loading: { asyncMap } }) => ({
      isLoading: get(asyncMap, DealerTypes.getDealers) > 0,
      dealers,
      dealersCount,
      isStopPagination
    })
  );

  const prevPage = usePrevious(page) || 1;

  const query = new URLSearchParams(search);
  const order = query.get('order');
  query.set('page', page.toString());

  useEffect(() => {
    dispatch(DealerActions.setTruncateDealers());
    setPage(1);
  }, [key]);

  useEffect(() => {
    if (prevPage === page && prevPage > 1) {
      return;
    }

    if (cancelToken !== null) {
      cancelToken.cancel();
    }

    cancelToken = axios.CancelToken.source();
    dispatch(DealerActions.getDealers(query, cancelToken.token));
  }, [key, page]);

  useEffect(() => {
    return () => {
      if (cancelToken !== null) {
        cancelToken.cancel();
      }
    };
  }, []);

  usePagination(() => {
    if (!isStopPagination && !isLoading) {
      setPage(prevPage => prevPage + 1);
    }
  }, [isStopPagination, isLoading]);

  return (
    <div className={styles.dealersList}>
      <div className={styles.header}>
        <span className={styles.count}>검색결과 {dealersCount ? toLocaleString(dealersCount) : '-'}명</span>
      </div>
      <div className={styles.actions}>
        <DropdownField
          options={orders}
          value={order}
          onChange={value => {
            if (typeof value === 'string') {
              query.set('order', value);
            } else if (value === null) {
              query.delete('order');
            }

            history.push(`?${query.toString()}`);
          }}
        />
      </div>
      <div>
        {dealers?.map((dealer, index) => {
          return <DealersListItem key={dealer.hash_id} index={index} dealer={dealer} />;
        })}
        {isLoading && <Loading />}
        {!isLoading && size(dealers) === 0 && (
          <div className={styles.noDealers}>
            검색된 담당자가 없습니다. <br />
            혹시 검색어에 오타가 있는지 확인해주세요!
          </div>
        )}
      </div>
    </div>
  );
});

export default DealersList;
